/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: 'Poppins', sans-serif;
}
